import { ServiceFetch } from '../service-fetch'

export default class PromotionCouponModel extends ServiceFetch {
    getPromotionCouponBy = (data) => this.authFetch({
        url: 'promotion-coupon/getPromotionCouponBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    verifyPromotionCouponBy = (data) => this.authFetch({
        url: 'promotion-coupon/verifyPromotionCouponBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}