import { env } from '../config/global'
import { token } from '../config/access-token'

export class ServiceFetch {
    directFetch = (data) => fetch(`${env.service_url}${data.url}`, {
        method: data.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: data.body,
    }).then((response) => response.json().then((e) => e)).catch((error) => ({ require: false, data: [], error, }))

    authFetch = async (data) => {
        let local = {}
        let body = {}
        if (data.body) {
            body = JSON.parse(data.body)
        }
        if (data.name) {
            local = JSON.parse(await localStorage.getItem(data.name));
            if (local) {
                body = {
                    ...body,
                    lastupdate: local.lastupdate,
                    adddate: local.adddate,
                }
            }
        }
        const response = await fetch(`${env.service_url}${data.url}`, {
            method: data.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem("x-access-token"),
            },
            body: JSON.stringify(body),
        }).then((response) => response.json().then((e) => e)).catch((error) => ({ require: false, data: [], error, }))

        if (response.unauthorized) {
            console.log('unauthorized', response.error)

            localStorage.clear()
            window.location.reload()
        }
        if (response.fact) {
            return local
        } else {
            localStorage.setItem(data.name, JSON.stringify(response));
        }

        return response
    }
}