import { ServiceFetch } from '../service-fetch'

export default class StockCardModel extends ServiceFetch {
    getStockCardBy = (data) => this.authFetch({
        url: 'stock-card/getStockCardBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLastDateStockCardBy = (data) => this.authFetch({
        url: 'stock-card/getLastDateStockCardBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}