import { ServiceFetch } from '../service-fetch'

export default class StockGroupModel extends ServiceFetch {
    generateStockGroupLastCode = (data) => this.authFetch({
        url: 'stock-group/generateStockGroupLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockGroupBy = (data) => this.authFetch({
        url: 'stock-group/getStockGroupBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockGroupByCode = (data) => this.authFetch({
        url: 'stock-group/getStockGroupByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockGroupBy = (data) => this.authFetch({
        url: 'stock-group/updateStockGroupBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    createStock = (data) => this.authFetch({
        url: 'stock-group/createStock',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertStockGroup = (data) => this.authFetch({
        url: 'stock-group/insertStockGroup',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockGroupByCode = (data) => this.authFetch({
        url: 'stock-group/deleteStockGroupByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}