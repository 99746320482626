import { ServiceFetch } from '../service-fetch'

export default class ReceiptModel extends ServiceFetch {
    generateReceiptLastCode = (data) => this.authFetch({
        url: 'receipt/generateReceiptLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptBy = (data) => this.authFetch({
        url: 'receipt/getReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getReceiptBySaleCloseShift = (data) => this.authFetch({
        url: 'receipt/getReceiptBySaleCloseShift',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getReceiptByCode = (data) => this.authFetch({
        url: 'receipt/getReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptCopyOldByCode = (data) => this.authFetch({
        url: 'receipt/getReceiptCopyOldByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptCopyByCode = (data) => this.authFetch({
        url: 'receipt/getReceiptCopyByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptOnPayment = (data) => this.authFetch({
        url: 'receipt/getReceiptOnPayment',
        method: 'POST',
        body: JSON.stringify(data),
        name: "getReceiptOnPayment"
    })

    requestCancelReceiptByCode = (data) => this.authFetch({
        url: 'receipt/requestCancelReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    increaseReceiptPrintByCode = (data) => this.authFetch({
        url: 'receipt/increaseReceiptPrintByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })


    editReceiptInPosBy = (data) => this.authFetch({
        url: 'receipt/editReceiptInPosBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateReceiptInPosBy = (data) => this.authFetch({
        url: 'receipt/updateReceiptInPosBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertReceipt = (data) => this.authFetch({
        url: 'receipt/insertReceipt',
        method: 'POST',
        body: JSON.stringify(data),
    })

    requestVerifyReceiptReturn = (data) => this.authFetch({
        url: 'receipt/requestVerifyReceiptReturn',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteReceiptByCode = (data) => this.authFetch({
        url: 'receipt/deleteReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getRequestReceiptReturnByCode = (data) => this.authFetch({
        url: 'receipt/getRequestReceiptReturnByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

}