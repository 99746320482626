import { ServiceFetch } from '../service-fetch'

export default class SaleCloseShiftModel extends ServiceFetch {
    generateSaleCloseShiftLastCode = (data) => this.authFetch({
        url: 'sale-close-shift/generateSaleCloseShiftLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleCloseShiftBy = (data) => this.authFetch({
        url: 'sale-close-shift/getSaleCloseShiftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleCloseShiftByCode = (data) => this.authFetch({
        url: 'sale-close-shift/getSaleCloseShiftByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateSaleCloseShiftBy = (data) => this.authFetch({
        url: 'sale-close-shift/generateSaleCloseShiftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertSaleCloseShift = (data) => this.authFetch({
        url: 'sale-close-shift/insertSaleCloseShift',
        method: 'POST',
        body: JSON.stringify(data),
    })
}