import { ServiceFetch } from '../service-fetch'

export default class DebitReceiptTypeModel extends ServiceFetch {
    getDebitReceiptTypeBy = (data) => this.authFetch({
        url: 'debit-receipt-type/getDebitReceiptTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
        name: "getDebitReceiptTypeBy"
    })

    getDebitReceiptTypeByCode = (data) => this.authFetch({
        url: 'debit-receipt-type/getDebitReceiptTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}