import { ServiceFetch } from '../service-fetch'

export default class ProductScaleModel extends ServiceFetch {
    getProductScaleBy = (data) => this.authFetch({
        url: 'product-scale/getProductScaleBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductScaleByCode = (data) => this.authFetch({
        url: 'product-scale/getProductScaleByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductScaleByProductCode = (data) => this.authFetch({
        url: 'product-scale/getProductScaleByProductCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}